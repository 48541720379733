"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-ignorelist-container"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('ignore_list')))]), _vm.onlineIgnoredUsers.length > 0 ? _c('table', {
    staticClass: "kiwi-ignorelist-table"
  }, [_vm._m(0), _vm._l(_vm.onlineIgnoredUsers, function (user) {
    return _c('tr', {
      key: user.nick
    }, [_c('td', [_vm._v(_vm._s(user.nick))]), _c('td', [_c('a', {
      staticClass: "u-link",
      on: {
        "click": function click($event) {
          var _context;
          user.ignore = false;
          _vm.network.ignored_list = (0, _filter.default)(_context = _vm.network.ignored_list).call(_context, function (n) {
            return n !== user.nick;
          });
        }
      }
    }, [_vm._v(" Remove ")])])]);
  })], 2) : _vm._e(), _vm.offlineIgnoredUsers.length > 0 ? _c('table', {
    staticClass: "kiwi-ignorelist-table"
  }, [_vm._m(1), _vm._l(_vm.offlineIgnoredUsers, function (user) {
    return _c('tr', {
      key: user
    }, [_c('td', [_vm._v(_vm._s(user))]), _c('td', [_c('a', {
      staticClass: "u-link",
      on: {
        "click": function click($event) {
          var _context2;
          _vm.network.ignored_list = (0, _filter.default)(_context2 = _vm.network.ignored_list).call(_context2, function (n) {
            return n !== user;
          });
        }
      }
    }, [_vm._v(" Remove ")])])]);
  })], 2) : _vm._e(), _vm.onlineIgnoredUsers.length === 0 && _vm.offlineIgnoredUsers.length === 0 ? _c('span', {
    staticClass: "kiwi-ignorelist-empty"
  }, [_vm._v(_vm._s(_vm.$t('ignore_list_empty')))]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Online")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Offline")])]);
}];
exports.staticRenderFns = staticRenderFns;